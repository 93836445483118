import { Component } from '@angular/core';

@Component({
    selector: 'app-editor-deny',
    templateUrl: './editor-deny.component.html',
    styleUrls: ['./editor-deny.component.scss'],
    animations: [],
    standalone: false
})
export class EditorDenyComponent {}
